import { Link } from "react-router-dom";
import styled from "styled-components";

const QUERY_STR = '?sidebar=%7B"type"%3A"profile"%7D';

export const PROFILE_URL = `/profile-new${QUERY_STR}`;
export const PLAN_URL = `/profile/plan${QUERY_STR}`;

export const SIDEBAR_ITEMS = [
  {
    title: "Profile",
    url: PROFILE_URL,
  },
  {
    title: "Teams",
    url: `/profile/teams${QUERY_STR}`,
  },
  {
    title: "Plans",
    url: PLAN_URL,
  },
  {
    title: "Payment",
    url: `/profile/billing${QUERY_STR}`,
  },
  {
    title: "Billing",
    url: `/profile/invoices${QUERY_STR}`,
  },
  // {
  //   title: "Usage",
  //   url: `/profile/usage${QUERY_STR}`,
  // },
  {
    title: "Integrations",
    url: `/profile/integrations${QUERY_STR}`,
  },
];

const SideBar = styled.div`
  padding-top: 16px;
`;

export const SideBarItem = styled(Link)`
  display: block;
  text-decoration: none;
  color: #e8ecefbf;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 8px;

  ${props =>
    props.isSelected &&
    `
    background: linear-gradient(270deg, #323337 50%, rgba(52, 95, 127, 0.5) 100%);
    color: white;
    box-shadow: 0px 4px 8px 0px #0000001A;
    box-shadow: 0px 1px 0px 0px #FFFFFF0D inset;
  `}

  padding: 12px 20px;
  padding-right: 143px;
  :hover {
    color: white;
  }
`;

const ProfileSidebar = () => {
  return (
    <SideBar>
      {SIDEBAR_ITEMS.map((item, index) => (
        <SideBarItem
          to={item.url}
          isSelected={
            window?.location?.pathname !== "/" &&
            item.url?.startsWith(window.location.pathname)
          }
          key={index}
        >
          {item.title}
        </SideBarItem>
      ))}
    </SideBar>
  );
};

export default ProfileSidebar;
