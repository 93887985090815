import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import {
  ARROW_KEYS,
  EMPTY_DOC,
  SF,
  VIEW_W,
  deleteText,
  drawDoc,
  getNearestCharIndexFromEvent,
  insertText,
  moveCaret,
} from "utils/word-utils-refactor";

const Container = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #f3f5f7 0%, #fdfdfd 100%);

  display: grid;
  justify-content: center;
`;

const Canvas = styled.canvas`
  border: 1px solid #ccc;
  border-top: none;
  border-bottom: none;
  background-color: white;
  height: 100%;
  width: ${VIEW_W}px;
  margin-top: 0px;
`;

const initCanvasSize = canvasRef => {
  const canvas = canvasRef?.current;
  if (!canvas) {
    return;
  }
  const rect = canvas.getBoundingClientRect();
  canvas.style.height = `${rect.height}px`;

  canvas.setAttribute("height", rect.height * SF);
  canvas.setAttribute("width", rect.width * SF);
};

const DocArea = ({
  className,
  doc = EMPTY_DOC,
  onDocChange = newDoc => {},
}) => {
  doc = doc || EMPTY_DOC;

  const [scrollY, setScrollY] = useState(0);
  const [xs, setXs] = useState([]);
  const [ys, setYs] = useState([]);

  const canvasRef = useRef();

  const { text, styles, selStart, selEnd } = doc;

  // init: size
  useEffect(() => {
    initCanvasSize(canvasRef);
  }, [canvasRef]);

  // init event listeners
  useEffect(() => {
    document.addEventListener("paste", onPaste);
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("paste", onPaste);
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [selStart, selEnd, text?.length, styles?.length]);

  // redrawing loop
  useEffect(() => {
    const ctx = canvasRef?.current?.getContext("2d");
    const [newXs, newYs] = drawDoc({ ctx, doc, scrollY, xs, ys });
    setXs(newXs || []);
    setYs(newYs || []);
  }, [text?.length, styles?.length, selStart, selEnd, canvasRef, scrollY]);

  const onMouseDown = e => {
    const ind = getNearestCharIndexFromEvent(e, scrollY, xs, ys);
    onDocChange({ ...doc, selStart: ind, selEnd: ind });
  };

  const onPaste = e => {
    e.preventDefault();
    const textToInsert = e.clipboardData.getData("text");
    const newDoc = insertText({ doc, textToInsert });
    onDocChange(newDoc);
  };

  const onWheel = e => {
    setScrollY(prev => Math.max(0, prev + e?.nativeEvent?.deltaY));
  };

  const onKeyDown = e => {
    if (e.metaKey || e.ctrlKey) {
      return;
    }

    if (ARROW_KEYS.includes(e.key)) {
      const newDoc = moveCaret({ doc, key: e.key });
      onDocChange(newDoc);
      return;
    }

    // if character key
    if (e.key.length === 1 || e.key === "Enter") {
      let charToInsert = e.key === "Enter" ? "\n" : e.key;
      const newDoc = insertText({ doc, textToInsert: charToInsert });
      onDocChange(newDoc);
      return;
    }

    if (e.key === "Backspace") {
      const newDoc = deleteText({ doc });
      onDocChange(newDoc);
      return;
    }
  };

  return (
    <Container className={className}>
      <Canvas onWheel={onWheel} onMouseDown={onMouseDown} ref={canvasRef} />
      {/* <div
        style={{
          position: "absolute",
          top: 100,
          right: 20,
        }}
      >
        numChars: {text?.length}, numPages: {Math.ceil(text?.length / 1500)}
      </div> */}
    </Container>
  );
};

export default DocArea;
