import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import NavHeader from "components/NavHeader";
import {
  getLoggedInUserName,
  updateUser,
} from "api/services/authenticationService";
import {
  getUserProfileByUserName,
  updateUserProfile,
} from "api/services/projectService";
import { PencilIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { getBase64FromImageFile } from "utils/common";
import LayoutNew from "components/LayoutNew";
import {
  EnvelopeIcon,
  LockIcon,
  PencilIconDetailed,
  PersonBlankIcon,
} from "components/IconsNew";
import ButtonWord from "components/ui/ButtonWord";

export const SIDEBAR_ITEMS = [
  {
    title: "Profile",
    url: "/profile-new",
  },
  {
    title: "Teams",
    url: "/profile/teams",
  },
  {
    title: "Plans",
    url: "/profile/plan",
  },
  {
    title: "Payment",
    url: "/profile/billing",
  },
  {
    title: "Usage",
    url: "/profile/usage",
  },
  {
    title: "Integrations",
    url: "/profile/integrations",
  },
];

const FIELDS = [
  { label: "Profile photo", name: "image", type: "image" },
  {
    label: "Name",
    name: "name",
    placeholder: "Enter your name",
    icon: <PersonBlankIcon />,
  },
  {
    label: "Email",
    name: "email",
    icon: <EnvelopeIcon />,
  },
  {
    label: "Organisation",
    name: "organisation",
    icon: <PersonBlankIcon />,
  },
  {
    label: "Job Role",
    name: "bio",
    icon: <PersonBlankIcon />,
  },
  { label: "Password", name: "password", icon: <LockIcon /> },
];

const Container = styled.div`
  display: grid;
  align-content: start;
  gap: 24px;
  padding: 80px 120px;
  min-height: 100%;
  background: linear-gradient(180deg, #f3f5f7 0%, #f5f5f5 100%);
  border-radius: 20px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
`;

export const SideBarItem = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${props => props.theme.color.closest};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  background-color: ${props => props.isSelected && "#CDDEFD"};
  padding: 7px 0;
  padding-left: 44px;
  padding-right: 143px;
  :hover {
    color: ${props => props.theme.color.primary};
  }
`;

const LogoPic = styled.div`
  width: 112px;
  height: 112px;
  border-radius: 50%;
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #eaeaea;
`;

const WhiteIconRect = styled.div`
  width: 400px;
  height: 50px;
  background-color: white;
  border-radius: 12px;
  display: grid;
  align-items: center;
  grid-template-columns: 24px 1fr;
  gap: 12px;
  padding: 14px;
  font-weight: 500;
`;

const StyledPencilIcon = styled(PencilIconDetailed)`
  position: absolute;
  top: 40px;
  right: 12px;
  opacity: 0;
  cursor: pointer;
`;

const StyledEditButton = styled.button`
  opacity: 0;
  font-family: "Montserrat", sans-serif;
  padding: 6px 16px;
  border-radius: 8px;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  font-weight: 500;
  cursor: pointer;

  :hover {
    background-color: white;
    color: black;
  }
`;

const FieldRow = styled.div`
  position: relative;
  display: grid;
  gap: 12px;
  align-items: center;
  width: max-content;
  :hover {
    ${StyledPencilIcon} {
      opacity: 1;
    }
    ${StyledEditButton} {
      opacity: 1;
    }
    ${LogoPic} {
      filter: brightness(0.4);
    }
  }

  svg {
    fill: #dddddd;
  }
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const ModalContent = styled.div`
  background-color: #fefefe;
  width: 500px;
  padding: 40px;
  border-radius: 24px;
  display: grid;
  gap: 10px;
`;

const ModalTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0em;
  padding-bottom: 20px;
`;

const StyledInput = styled.input`
  height: 52px;
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;
`;

const StyledButtonWord = styled(ButtonWord)`
  margin-top: 30px;
  height: 52px;
  font-size: 16px;
`;

const ProfilePic = styled.div`
  width: 93px;
  height: 93px;
  background-color: #f7f8fc;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 50%;
`;

const ProfilePicContainer = styled.div`
  :hover {
    ${StyledPencilIcon} {
      opacity: 1;
    }
  }
`;

const EditImageFieldModalTrigger = ({
  field = { name: "", label: "" },
  profile = {},
  onSuccess = () => {},
  style = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [profileFields, setProfileFields] = useState(profile);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setProfileFields(profile);
  }, [JSON.stringify(profile), isOpen]);

  const doUpdateProfile = async () => {
    setIsUpdating(true);
    const { data, error: updateErr } = await updateUserProfile(
      {},
      profileFields
    );
    setProfileFields(data);
    setError(updateErr);
    setIsUpdating(false);
    if (!updateErr) {
      onSuccess();
      setIsOpen(false);
    }
  };

  return (
    <>
      <StyledEditButton
        style={{
          position: "absolute",
          top: "50%",
          right: "50%",
          transform: "translate(50%, 0%)",
        }}
        onClick={() => setIsOpen(true)}
      >
        Edit
      </StyledEditButton>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <ModalTitle>{field?.label}</ModalTitle>
          <StyledInput
            type="file"
            onChange={async e => {
              const file = e.target.files[0];
              const base64Str = await getBase64FromImageFile(file);

              setProfileFields({
                ...profileFields,
                [field?.name]: base64Str,
              });
            }}
          />
          <div>Image must be less than 1MB</div>
          <ButtonWord
            style={{ justifySelf: "start" }}
            onClick={() =>
              setProfileFields({ ...profileFields, [field?.name]: "" })
            }
          >
            Clear
          </ButtonWord>
          <LogoPic src={profileFields?.[field?.name]} />
          <StyledButtonWord
            isPrimary
            disabled={isUpdating}
            onClick={doUpdateProfile}
          >
            Update
          </StyledButtonWord>
          <div>{error?.message || ""}</div>
        </ModalContent>
      </Modal>
    </>
  );
};

const EditFieldModalTrigger = ({
  field = { name: "", label: "", placeholder: "" },
  profile = {},
  onSuccess = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [profileFields, setProfileFields] = useState(profile);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setProfileFields(profile);
  }, [JSON.stringify(profile), isOpen]);

  const doUpdateProfile = async () => {
    setIsUpdating(true);
    const { data, error: updateErr } = await updateUserProfile(
      {},
      profileFields
    );
    setProfileFields(data);
    setError(updateErr);
    setIsUpdating(false);
    if (!updateErr) {
      onSuccess();
      setIsOpen(false);
    }
  };

  return (
    <>
      <StyledPencilIcon onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <ModalTitle>{field?.label}</ModalTitle>
          <StyledInput
            value={profileFields?.[field?.name]}
            placeholder={field?.placeholder || ""}
            onChange={e =>
              setProfileFields({
                ...profileFields,
                [field?.name]: e.target.value,
              })
            }
          />
          <StyledButtonWord
            isPrimary
            disabled={isUpdating}
            onClick={doUpdateProfile}
          >
            Update
          </StyledButtonWord>
          <div>{error?.message || ""}</div>
        </ModalContent>
      </Modal>
    </>
  );
};

const EditPasswordModalTrigger = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setError(null);
    setConfirmPassword("");
    setNewPassword("");
  }, [isOpen]);

  const doUpdateUser = async () => {
    setError(null);
    if (newPassword !== confirmPassword) {
      setError({ message: "Passwords do not match" });
      return;
    }

    setIsUpdating(true);
    const userName = getLoggedInUserName();
    const { error: updateErr } = await updateUser(userName, {
      id: userName,
      password: newPassword,
    });

    setError(updateErr);
    setIsUpdating(false);
    if (!updateErr) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <StyledPencilIcon onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <ModalTitle>Password</ModalTitle>
          <BoldSpan>New Password</BoldSpan>
          <StyledInput
            type="password"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
          />
          <Gap height="12px" />
          <BoldSpan>Confirm new password</BoldSpan>
          <StyledInput
            type="password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
          />
          <StyledButtonWord
            isPrimary
            disabled={isUpdating}
            onClick={doUpdateUser}
          >
            Update
          </StyledButtonWord>
          <div style={{ color: "#d9534f" }}>{error?.message || ""}</div>
        </ModalContent>
      </Modal>
    </>
  );
};

const ProfilePageNew = () => {
  const [profile, setProfile] = useState({});

  useEffect(() => {
    doPopulateProfile();
  }, []);

  const doPopulateProfile = async () => {
    const userName = getLoggedInUserName();
    const { data } = await getUserProfileByUserName(userName);
    setProfile(data);
  };

  return (
    <LayoutNew>
      <Container>
        <Title>My Profile</Title>
        {FIELDS?.map((field, index) => {
          if (field.type === "image") {
            return (
              <FieldRow key={index}>
                <BoldSpan>{field.label}</BoldSpan>
                <LogoPic src={profile?.[field?.name]} />
                <EditImageFieldModalTrigger
                  field={field}
                  profile={profile}
                  onSuccess={doPopulateProfile}
                />
              </FieldRow>
            );
          }

          if (field?.name === "password") {
            return (
              <FieldRow key={index}>
                <BoldSpan>{field?.label}</BoldSpan>
                <WhiteIconRect>
                  {field?.icon}
                  ********
                </WhiteIconRect>
                <EditPasswordModalTrigger />
              </FieldRow>
            );
          }

          return (
            <FieldRow key={index}>
              <BoldSpan>{field.label}</BoldSpan>
              <WhiteIconRect>
                {field?.icon}
                {profile?.[field?.name]}
              </WhiteIconRect>
              <EditFieldModalTrigger
                field={field}
                profile={profile}
                onSuccess={doPopulateProfile}
              />
            </FieldRow>
          );
        })}
      </Container>
    </LayoutNew>
  );
};

export default ProfilePageNew;
