import styled from "styled-components";
import Modal from "components/ui/Modal";

const ModalContent = styled.div`
  padding: 32px;
  width: 400px;
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  gap: 20px;
  svg {
    height: 100px !important;
    fill: #424242;
  }

  font-weight: 700;
  font-size: 24px;
  text-align: center;
  line-height: 1.5;
`;

const MessageModal = ({ content, setContent = () => {} }) => {
  return (
    <Modal open={!!content} handleClose={() => setContent(null)}>
      <ModalContent>{content}</ModalContent>
    </Modal>
  );
};

export default MessageModal;
