import styled from "styled-components";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LayoutNew from "components/LayoutNew";
import ButtonWord from "components/ui/ButtonWord";
import Modal from "components/ui/Modal";
import { useState } from "react";
import {
  deleteOauthToken,
  getOauthCallback,
  getOauthLogin,
  getOauthToken,
} from "api/backend/projectServiceEndpoints";
import { useEffect } from "react";

const Container = styled.div`
  display: grid;
  align-content: start;
  gap: 24px;
  padding: 80px 120px;
  min-height: 100%;
  background: linear-gradient(180deg, #f3f5f7 0%, #f5f5f5 100%);
  border-radius: 20px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: start;
  width: 100%;
`;

const Card = styled.div`
  padding: 20px;
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 12px;

  width: 236px;
`;

const CardTitle = styled.div`
  font-weight: 600;
  line-height: 1.5;
`;

const CardLogo = styled.img`
  height: 40px;
`;

const CardButton = styled(ButtonWord)`
  padding: 0 4px;
`;

const CARDS = [
  {
    title: "LinkedIn",
    logoSrc: "/images/logo-linkedin.png",
    provider: "linkedin",
  },
  {
    title: "Gmail",
    logoSrc: "/images/logo-gmail.png",
    provider: "gmail",
  },
  {
    title: "Hubspot",
    logoSrc: "/images/logo-hubspot.png",
    provider: "hubspot",
  },
  {
    title: "Google Drive",
    logoSrc: "/images/logo-googledrive.png",
    provider: "googledrive",
  },
  {
    title: "One Drive",
    logoSrc: "/images/logo-onedrive.png",
    provider: "onedrive",
  },
];

const ModalContent = styled.div`
  padding: 36px;
  display: grid;
  position: relative;
`;

const SeePlansModal = ({ trigger }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <CardButton isPrimary onClick={() => setIsOpen(true)}>
        Connect
      </CardButton>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <div style={{ fontWeight: 600, marginBottom: 32 }}>
            Integrations are only available in the “Teams” plan.
          </div>

          <div style={{ justifySelf: "end", display: "flex", gap: 10 }}>
            <CardButton
              style={{ padding: "2px 20px" }}
              isPrimary
              onClick={() => {
                // go to /profile/plan, while keeping search params
                navigate(`/profile/plan?${searchParams.toString()}`);
              }}
            >
              See plans
            </CardButton>
            <CardButton
              onClick={() => setIsOpen(false)}
              style={{ padding: "2px 20px" }}
            >
              Cancel
            </CardButton>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export const PROVIDERS = [
  "gmail",
  "hubspot",
  "linkedin",
  "googledrive",
  "onedrive",
];

const ProfilePageIntegrations = () => {
  const [searchParams] = useSearchParams();
  const { providerInUrl } = useParams();
  const navigate = useNavigate();

  const [providerToToken, setProviderToToken] = useState({
    google: "",
    hubspot: "",
    linkedin: "",
    googledrive: "",
    onedrive: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    PROVIDERS.forEach(provider => {
      doPopulateToken(provider);
    });
  }, []);

  const doPopulateToken = async provider => {
    const { data } = await getOauthToken(provider);
    setProviderToToken(prev => ({ ...prev, [provider]: data }));
  };

  const doProviderLogin = async provider => {
    const { data, error } = await getOauthLogin(provider, {
      callbackUrl: `${window.location.origin}/profile/integrations/${provider}`,
    });
    if (error) {
      setError(error);
      return;
    }

    window.location = data;
  };

  useEffect(() => {
    const code = searchParams?.get("code");
    if (!code || !providerInUrl) {
      return;
    }
    doRegisterCode(providerInUrl, code);
  }, [searchParams?.get("code")]);

  const doRegisterCode = async (provider, code) => {
    await getOauthCallback(provider, { code });
    doPopulateToken(provider);
    navigate("/profile/integrations");
  };

  const doDeleteToken = async provider => {
    const { error } = await deleteOauthToken(provider);
    setError(error);
    doPopulateToken(provider);
  };

  return (
    <LayoutNew>
      <Container>
        <Title>Integrations</Title>
        {error && <div>{error}</div>}
        <Cards>
          {CARDS.map((card, index) => (
            <Card key={index}>
              <CardLogo src={card.logoSrc} />
              <CardTitle>{card.title}</CardTitle>
              {/* <SeePlansModal /> */}
              <CardButton
                isPrimary
                disabled={providerToToken?.[card?.provider]}
                onClick={() => doProviderLogin(card?.provider)}
              >
                Connect
              </CardButton>
              <CardButton
                isPrimary
                disabled={!providerToToken?.[card?.provider]}
                onClick={() => doDeleteToken(card?.provider)}
              >
                Disconnect
              </CardButton>
            </Card>
          ))}
        </Cards>
      </Container>
    </LayoutNew>
  );
};

export default ProfilePageIntegrations;
