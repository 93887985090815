import { clamp, clone, cloneDeep, range } from "lodash";

export const VIEW_W = 816;
const VIEW_H = 700;

export const SF = 2;

const FONT_SIZE = 16;
const LINE_HEIGHT = FONT_SIZE * 3;
const PADDING = 10;
const START_X = 95 * SF;
const START_Y = 100 * SF;
const END_X = (VIEW_W - 100) * SF;

const STYLE_FIELD_TO_VALUE = {
  fontSize: 24,
  fontWeight: "bold",
};

const STYLE_FIELDS = Object.keys(STYLE_FIELD_TO_VALUE);

export const ARROW_KEYS = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];

const isWordOverlappingEnd = ({ x, text, i, ctx }) => {
  if (text?.[i] !== " ") {
    return false;
  }

  let word = "";
  let j = i + 1;
  while (text[j] !== " " && text[j] !== "\n" && j < text.length) {
    word += text[j];
    j++;
  }

  return x + ctx.measureText(word).width > END_X;
};

const getNextPosition = ({ x, y, text, i, ctx }) => {
  const char = text[i];
  const charWidth = ctx.measureText(char).width;

  let shouldStartNewLine =
    char === "\n" || isWordOverlappingEnd({ x, text, i, ctx });
  if (shouldStartNewLine) {
    y += LINE_HEIGHT;
    x = START_X;
    return { x, y };
  }

  x += charWidth;
  return { x, y };
};

const drawCaret = ({ x, y, ctx }) => {
  ctx.lineWidth = 4;
  ctx.beginPath();
  ctx.moveTo(x, y - LINE_HEIGHT);
  ctx.lineTo(x, y);
  ctx.stroke();
};

const drawSelectionBox = ({ x, y, ctx, char }) => {
  const prevFillStyle = ctx.fillStyle;
  ctx.fillStyle = "lightblue";
  ctx.fillRect(x, y - LINE_HEIGHT, ctx.measureText(char).width, LINE_HEIGHT);
  ctx.fillStyle = prevFillStyle;
};

export const drawDoc = ({ doc, ctx, scrollY, xs = [], ys = [] }) => {
  if (!ctx) {
    return [];
  }
  ctx.clearRect(0, 0, VIEW_W * SF, VIEW_H * SF);
  const { text, selStart, selEnd, styles } = doc || EMPTY_DOC;
  let selSmaller = Math.min(selStart, selEnd);
  let selBigger = Math.max(selStart, selEnd);

  ctx.font = `${FONT_SIZE * SF}px Arial`;

  let newXs = [...xs];
  let newYs = [...ys];

  newXs[0] = START_X;
  newYs[0] = START_Y;

  let i = newYs.findIndex(y => y - scrollY > 0);
  i = clamp(i, 0, text.length);
  let x = newXs[i];
  let y = newYs[i];

  while (i < text.length && i >= 0) {
    ctx.font = `${FONT_SIZE * SF}px Arial`;
    newXs[i] = x;
    newYs[i] = y;

    if (i === selStart && i === selEnd) {
      drawCaret({ x, y: y - scrollY, ctx });
    }

    if (styles[i]) {
      ctx.font = `${doc.styles[i].fontSize * SF}px Arial`;
    }

    if (i >= selSmaller && i < selBigger) {
      drawSelectionBox({ x, y: y - scrollY, ctx, char: text[i] });
    }

    ctx.fillText(text[i], x, y - scrollY);
    ({ x, y } = getNextPosition({ x, y, text, i, ctx }));

    if (y - scrollY > VIEW_H * SF) {
      // SOLVES: coordinates beyond the page could become out of date.
      // caused issues when finding nearest index from mouse event
      newXs = newXs.slice(0, i + 1);
      newYs = newYs.slice(0, i + 1);
      break;
    }

    i++;
  }

  if (selStart === selEnd && selStart === text.length) {
    drawCaret({ x, y: y - scrollY, ctx });
  }

  return [newXs, newYs];
};

const getNearestCharIndex = ({ x, y, xs = [], ys = [] }) => {
  let nearestYIndex = getIndexOfClosest(ys, range(ys.length), y + LINE_HEIGHT);
  let lineY = ys?.[nearestYIndex];

  // Subtract until we find the nearest x
  let adjustedIndex = nearestYIndex;
  let adjustedIndexY = ys?.[adjustedIndex];
  let minXDistance = Math.abs(x - xs[adjustedIndex]);
  let minXIndex = adjustedIndex;
  while (true) {
    adjustedIndex--;
    adjustedIndexY = ys?.[adjustedIndex];

    if (adjustedIndexY !== lineY || adjustedIndex < 0) {
      break;
    }

    let xDistance = Math.abs(x - xs[adjustedIndex]);
    if (xDistance < minXDistance) {
      minXDistance = xDistance;
      minXIndex = adjustedIndex;
    }
  }

  return minXIndex;
};

const getIndexOfClosest = (values = [], indices = [], target = 0) => {
  if (!indices.length) {
    return 0;
  }

  if (indices.length === 1) {
    return indices[0];
  }

  let midIndex = Math.floor(values.length / 2);
  let midValue = values[midIndex];

  if (target <= midValue) {
    let leftValues = values.slice(0, midIndex);
    let leftIndices = indices.slice(0, midIndex);
    return getIndexOfClosest(leftValues, leftIndices, target);
  }

  let rightValues = values.slice(midIndex);
  let rightIndices = indices.slice(midIndex);
  return getIndexOfClosest(rightValues, rightIndices, target);
};

export const getNearestCharIndexFromEvent = (
  e,
  scrollY = 0,
  xs = [],
  ys = []
) => {
  const { offsetX, offsetY } = e?.nativeEvent;
  const x = offsetX * SF;
  const y = offsetY * SF;
  let nearestIndex = getNearestCharIndex({
    x,
    y: y + scrollY,
    xs,
    ys,
  });

  return nearestIndex;
};

export const insertText = ({ doc = {}, textToInsert = "" }) => {
  let newDoc = cloneDeep(doc);
  const selSmaller = Math.min(newDoc.selStart, newDoc.selEnd);
  const selBigger = Math.max(newDoc.selStart, newDoc.selEnd);

  newDoc.text =
    newDoc.text.slice(0, selSmaller) +
    textToInsert +
    newDoc.text.slice(selBigger);

  const styleAtStart = newDoc.styles[selSmaller];
  newDoc.styles = [
    ...newDoc.styles.slice(0, selSmaller),
    ...Array(textToInsert.length).fill(styleAtStart),
    ...newDoc.styles.slice(selBigger),
  ];

  newDoc.selStart += textToInsert.length;
  newDoc.selEnd = newDoc.selStart;

  return newDoc;
};

export const moveCaret = ({ doc = {}, key = "" }) => {
  let newDoc = cloneDeep(doc);
  let caretIndex = newDoc.selStart;

  if (key === "ArrowRight") {
    caretIndex++;
  }
  if (key === "ArrowLeft") {
    caretIndex--;
  }
  caretIndex = Math.max(0, Math.min(newDoc.text.length, caretIndex));

  newDoc.selStart = caretIndex;
  newDoc.selEnd = caretIndex;

  return newDoc;
};

export const deleteText = ({ doc = {} }) => {
  let newDoc = cloneDeep(doc);

  if (newDoc.selStart === newDoc.selEnd) {
    newDoc.selStart--;
  }

  const selSmaller = Math.min(newDoc.selStart, newDoc.selEnd);
  const selBigger = Math.max(newDoc.selStart, newDoc.selEnd);

  newDoc.text = newDoc.text.slice(0, selSmaller) + newDoc.text.slice(selBigger);
  newDoc.styles = [
    ...newDoc.styles.slice(0, selSmaller),
    ...newDoc.styles.slice(selBigger),
  ];
  newDoc.xs = [];
  newDoc.ys = [];

  newDoc.selStart = selSmaller;
  newDoc.selEnd = selSmaller;

  return newDoc;
};

export const EMPTY_DOC = {
  text: "",
  styles: [],
  selStart: 0,
  selEnd: 0,
};
