import styled from "styled-components";

import {
  Chat,
  Replay,
  RestorePage,
  Visibility,
  VisibilityOff,
  ViewColumn,
  NotInterested,
  HorizontalSplit,
} from "@material-ui/icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import {
  postFilesRerunForFileId,
  postFilesRerunForFileIdForPage,
} from "api/services/filesService";
import Tooltip from "components/ui/Tooltip";

const IconButton = styled.div`
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-self: end;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const ProgressPercentge = styled.div`
  padding: 0 5px;
  font-size: 14px;
  color: ${props => props.theme.color.primary};
  font-weight: 600;
`;

const BottomRightAlignedTooltip = styled(Tooltip)`
  right: 0;
  transform: translateX(calc(-100% + 15px)) translateY(10%);
  color: ${props => props.theme.color.furthest};
  background-color: ${props => props.theme.color.closest};
  padding: 2px 5px;
`;

const FileRerunButtonsAndProgress = ({
  progress,
  visibleButtons = ["chat", "eye", "rerunfile", "rerunpage"],
}) => {
  const [searchParams] = useSearchParams();
  const naigate = useNavigate();
  const { fileId } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const pageNumber = searchParams.get("pageNumber") || 0;

  const areAnnotationsVisible =
    searchParams.get("areAnnotationsVisible") === "true";
  const areColumnBoxesVisible =
    searchParams.get("areColumnBoxesVisible") === "true";
  const areRowBoxesVisible = searchParams.get("areRowBoxesVisible") === "true";

  const isChatVisible = searchParams.get("isChatVisible") === "true";

  const doRerunPageAndReload = async () => {
    setIsLoading(true);
    await postFilesRerunForFileIdForPage(fileId, pageNumber);
    window.location.reload();
  };

  const doRerunFileAndReload = async () => {
    setIsLoading(true);
    await postFilesRerunForFileId(fileId, pageNumber);
    window.location.reload();
  };

  return (
    <Container isDisabled={isLoading}>
      {/* <BottomRightAlignedTooltip
        title={
          areColumnBoxesVisible ? "Hide column boxes" : "Show only column boxes"
        }
      >
        <IconButton
          onClick={() => {
            if (areColumnBoxesVisible) {
              searchParams.delete("areColumnBoxesVisible");
            } else {
              searchParams.set("areColumnBoxesVisible", true);
              searchParams.delete("areRowBoxesVisible");
            }
            naigate({ search: searchParams.toString() });
          }}
        >
          {areColumnBoxesVisible ? <NotInterested /> : <ViewColumn />}
        </IconButton>
      </BottomRightAlignedTooltip>
      <BottomRightAlignedTooltip
        title={areRowBoxesVisible ? "Hide row boxes" : "Show only row boxes"}
      >
        <IconButton
          onClick={() => {
            searchParams.set("areRowBoxesVisible", !areRowBoxesVisible);
            if (areRowBoxesVisible) {
              searchParams.delete("areRowBoxesVisible");
            } else {
              searchParams.set("areRowBoxesVisible", true);
              searchParams.delete("areColumnBoxesVisible");
            }
            naigate({ search: searchParams.toString() });
          }}
        >
          {areRowBoxesVisible ? <NotInterested /> : <HorizontalSplit />}
        </IconButton>
      </BottomRightAlignedTooltip>
      {visibleButtons?.includes("chat") && (
        <BottomRightAlignedTooltip
          title={isChatVisible ? "Hide chat" : "Show chat"}
        >
          <IconButton
            onClick={() => {
              searchParams.set("isChatVisible", !isChatVisible);
              if (isChatVisible) {
                searchParams.delete("isChatVisible");
              }
              naigate({ search: searchParams.toString() });
            }}
          >
            <Chat />
          </IconButton>
        </BottomRightAlignedTooltip>
      )} */}
      {visibleButtons?.includes("eye") && (
        <BottomRightAlignedTooltip
          title={areAnnotationsVisible ? "Hide boxes" : "Show boxes"}
        >
          <IconButton
            onClick={() => {
              searchParams.set("areAnnotationsVisible", !areAnnotationsVisible);
              if (areAnnotationsVisible) {
                searchParams.delete("areAnnotationsVisible");
              }
              naigate({ search: searchParams.toString() });
            }}
          >
            {areAnnotationsVisible ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </BottomRightAlignedTooltip>
      )}
      {visibleButtons?.includes("rerunfile") && (
        <BottomRightAlignedTooltip title="Re run file">
          <IconButton onClick={doRerunFileAndReload}>
            <Replay />
          </IconButton>
        </BottomRightAlignedTooltip>
      )}
      {visibleButtons?.includes("rerunpage") && (
        <BottomRightAlignedTooltip title="Re run page">
          <IconButton onClick={doRerunPageAndReload}>
            <RestorePage />
          </IconButton>
        </BottomRightAlignedTooltip>
      )}
      {progress !== 100 && <ProgressPercentge>{progress}%</ProgressPercentge>}
    </Container>
  );
};

export default FileRerunButtonsAndProgress;
