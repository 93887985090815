import DocArea from "components/DocArea";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  background-color: white;
  height: 100%;
  border-radius: 20px;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const OuterContainer = styled.div`
  padding: 20px;
  background-color: #131718;
  height: 100vh;
`;

const TopBar = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ccc;
`;

const StyledDocArea = styled(DocArea)`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const WordDocPageRefactor = () => {
  const [doc, setDoc] = useState(null);

  return (
    <OuterContainer>
      <Container>
        <TopBar>content</TopBar>
        <StyledDocArea doc={doc} onDocChange={newDoc => setDoc(newDoc)} />
      </Container>
    </OuterContainer>
  );
};

export default WordDocPageRefactor;
