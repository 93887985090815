import styled from "styled-components";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useState, useEffect } from "react";

import { CenteredWithTopNavLayout } from "components/Layout";
import NavHeader from "components/NavHeader";
import {
  deleteOauthToken,
  getEmails,
  getOauthCallback,
  getOauthLogin,
  getOauthToken,
  postEmails,
} from "api/backend/projectServiceEndpoints";
import ErrorMessageModal from "components/ErrorMessageModal";
import LayoutNew from "components/LayoutNew";
import { PROVIDERS } from "pages/profile-new/integrations";

const ButtonWord = styled.button`
  padding: 0 4px;
  gap: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  background-color: #141718;
  color: #ffffff;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  white-space: nowrap;
  :hover {
    color: #eaeaea;
  }
  :disabled {
    opacity: 0.5;
  }
`;

const StyledCenteredWithTopNavLayout = styled(CenteredWithTopNavLayout)`
  background-color: #ffffff;
  padding: 0;
  height: 100vh;
  grid-template-rows: auto 1fr;
`;

const StyledNavHeader = styled(NavHeader)`
  position: fixed;
  top: 0;
  right: 0;
  background-color: transparent;
  box-shadow: none;
`;

const Title = styled.div`
  background-color: #f7f8fc;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  padding: 0 40px;
  height: 50px;
  line-height: 50px;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
`;

const ProfileContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  padding: 40px;
  gap: 50px;
  background-color: #ffffff;
  border-top: 1px solid #00000080;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`;

const SideBar = styled.div`
  padding-top: 16px;
`;

export const SideBarItem = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${props => props.theme.color.closest};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  background-color: ${props => props.isSelected && "#CDDEFD"};
  padding: 7px 0;
  padding-left: 44px;
  padding-right: 143px;
  :hover {
    color: ${props => props.theme.color.primary};
  }
`;

const ProfileFields = styled.div`
  display: grid;
  width: 100%;
  margin-top: 10px;
  align-content: start;
  align-items: center;
  justify-content: start;
  grid-template-columns: 150px 200px 1fr;
  column-gap: 10px;
  row-gap: 10px;
  padding-right: 20px;
`;

const Token = styled.div`
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  padding: 4px;
  background-color: #eaeaea;
  border-radius: 4px;
  font-family: monospace;
`;

const SectionTitle = styled.div`
  font-weight: 600;
  grid-column: span 3;
`;

const Table = styled.table`
  width: 100%;
`;

const Tr = styled.tr``;

const Td = styled.td`
  padding: 8px;
  border: 1px solid #eaeaea;
`;

const Th = styled.th`
  padding: 8px;
  border: 1px solid #eaeaea;
  font-weight: 600;
  text-align: left;
  position: sticky;
  top: 0;
  background-color: #ffffff;
`;

const COLUMNS = ["createdAt", "to", "subject", "status"];

// const RANOM_EMAILS = range(1000).map(i => ({
//   id: i,
//   createdAt: "-",
//   name: "name",
//   subtitle: "subtitle",
//   content: "content",
// }));

const ProfilePageIntegrationsOld = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { providerInUrl } = useParams();

  const [providerToToken, setProviderToToken] = useState({
    google: "",
    hubspot: "",
    linkedin: "",
    googledrive: "",
    onedrive: "",
  });
  const [error, setError] = useState(null);

  const [recipient, setRecipient] = useState("");
  const [title, setTitle] = useState("");
  const [bodyTextHtml, setBodyTextHtml] = useState("");
  const [emails, setEmails] = useState([]);
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  useEffect(() => {
    PROVIDERS.forEach(provider => {
      doPopulateToken(provider);
    });

    // doPopulateToken("google");
    // doPopulateToken("hubspot");
    // doPopulateToken("linkedin");
    // googledrive
    // onedrive
    doPopulateEmails();
  }, []);

  useEffect(() => {
    const code = searchParams?.get("code");
    if (!code || !providerInUrl) {
      return;
    }
    doRegisterCode(providerInUrl, code);
  }, [searchParams?.get("code")]);

  const doProviderLogin = async provider => {
    const { data, error } = await getOauthLogin(provider, {
      callbackUrl: `${window.location.origin}/profile/integrations/${provider}`,
    });
    if (error) {
      setError(error);
      return;
    }

    window.location = data;
  };

  const doRegisterCode = async (provider, code) => {
    await getOauthCallback(provider, { code });
    // if (error) {
    //   setError(error);
    //   return;
    // }
    doPopulateToken(provider);
    navigate("/profile/integrations");
  };

  const doPopulateToken = async provider => {
    const { data } = await getOauthToken(provider);
    setProviderToToken(prev => ({ ...prev, [provider]: data }));
  };

  const doDeleteToken = async provider => {
    const { error } = await deleteOauthToken(provider);
    setError(error);
    doPopulateToken(provider);
  };

  const doSendEmail = async () => {
    setIsSendingEmail(true);
    const emailBody = {
      recipients: [recipient],
      title,
      bodyTextHtml,
    };

    const { error } = await postEmails({ track: true }, emailBody);
    setIsSendingEmail(false);
    setError(error);
    setRecipient("");
    setTitle("");
    setBodyTextHtml("");
    doPopulateEmails();
  };

  const doPopulateEmails = async () => {
    const { data } = await getEmails();
    setEmails(data || []);
  };

  return (
    <LayoutNew>
      <Title>Integrations</Title>
      <ProfileContent>
        <ProfileFields>
          {PROVIDERS.map(provider => (
            <>
              <ButtonWord
                disabled={providerToToken?.[provider]}
                onClick={() => doProviderLogin(provider)}
              >
                Connect {provider}
              </ButtonWord>
              <ButtonWord
                disabled={!providerToToken?.[provider]}
                onClick={() => doDeleteToken(provider)}
              >
                Delete {provider} Token
              </ButtonWord>
              <Token>{providerToToken?.[provider] || "-"}</Token>
            </>
          ))}
          {/* Google */}
          {/* <ButtonWord
            disabled={providerToToken?.google}
            onClick={() => doProviderLogin("google")}
          >
            Connect Google
          </ButtonWord>
          <ButtonWord
            disabled={!providerToToken?.google}
            onClick={() => doDeleteToken("google")}
          >
            Delete Google Token
          </ButtonWord>
          <Token>{providerToToken?.google || "-"}</Token> */}

          {/* Hubspot */}
          {/* <ButtonWord
            disabled={providerToToken?.hubspot}
            onClick={() => doProviderLogin("hubspot")}
          >
            Connect Hubspot
          </ButtonWord>
          <ButtonWord
            disabled={!providerToToken?.hubspot}
            onClick={() => doDeleteToken("hubspot")}
          >
            Delete Hubspot Token
          </ButtonWord>
          <Token>{providerToToken?.hubspot || "-"}</Token> */}

          {/* Linkedin */}
          {/* <ButtonWord
            disabled={providerToToken?.linkedin}
            onClick={() => doProviderLogin("linkedin")}
          >
            Connect Linkedin
          </ButtonWord>
          <ButtonWord
            disabled={!providerToToken?.linkedin}
            onClick={() => doDeleteToken("linkedin")}
          >
            Delete Linkedin Token
          </ButtonWord>
          <Token>{providerToToken?.linkedin || "-"}</Token> */}
          <SectionTitle style={{ marginTop: 40 }}>Send Email</SectionTitle>
          <div
            style={{
              gridColumn: "span 3",
              display: "grid",
              gridTemplateColumns: "1fr 2fr",
              gap: 20,
            }}
          >
            <div style={{ display: "grid", gap: 10 }}>
              <input
                disabled={isSendingEmail}
                placeholder="To"
                value={recipient}
                onChange={e => setRecipient(e.target.value)}
              />
              <input
                disabled={isSendingEmail}
                placeholder="Subject"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
              <textarea
                disabled={isSendingEmail}
                style={{ height: 300 }}
                placeholder="Body"
                value={bodyTextHtml}
                onChange={e => setBodyTextHtml(e.target.value)}
              />
              <ButtonWord
                disabled={isSendingEmail}
                onClick={doSendEmail}
                style={{ justifySelf: "start" }}
              >
                Send
              </ButtonWord>
            </div>
            <div
              style={{
                width: "100%",
                height: 400,
                overflow: "auto",
                border: "1px solid #eaeaea",
              }}
            >
              <Table>
                <thead>
                  <Tr>
                    {COLUMNS.map(column => (
                      <Th>{column}</Th>
                    ))}
                  </Tr>
                </thead>
                <tbody>
                  {emails?.map(blog => (
                    <Tr>
                      {COLUMNS.map(column => (
                        <Td>{blog[column]}</Td>
                      ))}
                    </Tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </ProfileFields>
      </ProfileContent>
      <ErrorMessageModal
        message={error?.message}
        onDismiss={() => setError(null)}
      />
    </LayoutNew>
  );
};

export default ProfilePageIntegrationsOld;
